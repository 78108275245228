.search_input_panel_head {
  padding-right: 4px !important;
}
.panel_select {
  width: 100px;
  text-align: right;
  color: #24C7F9;
  :global {
    .ant-select-arrow span {
      color: #24C7F9;
    }
  }
}

.parkinglotmore{
  position: absolute;
    top: -44px;
    right: 4px;
    border: 1px solid #20BCFC;
    color: #20BCFC;
    font-size: 14px;
    padding: 5px 15px;
    cursor: pointer;
}