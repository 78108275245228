:global{
    .ant-table-body{
        table{
            border: 0;
        }
    }
  
}

.parkinglotmore{
  position: absolute;
    top: -44px;
    right: 4px;
    border: 1px solid #20BCFC;
    color: #20BCFC;
    font-size: 14px;
    padding: 5px 15px;
    cursor: pointer;
}